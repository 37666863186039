import Vue from 'vue';

export default class {
    
    getUserTimesheet( code, from, to ) {
        Vue.http.get( 'documents/employee/' + code + `?from=${from.toISODate()}&to=${to.toISODate()}` )
        .then(
            result => {
                window.open( result.data.url, '_blank' );
            }
        )
    }

    getEmployeeCodes( location_id ) {
        Vue.http.get( 'documents/location_employees/' + location_id )
        .then(
            result => {
                window.open( result.data.url, '_blank' );
            }
        )
    }

    getLocationReport( location_id, from, to ) {
        Vue.http.get( 'documents/location_report/' + location_id + `?from=${from.toISODate()}&to=${to.toISODate()}` )
        .then(
            result => {
                window.open( result.data.url, '_blank' );
            }
        )
    }

    getAllEmployeeTimesheets( location_id, from, to ) {
        Vue.http.get( 'documents/all_timesheets/' + location_id + `?from=${from.toISODate()}&to=${to.toISODate()}` )
        .then(
            result => {
                window.open( result.data.url, '_blank' );
            }
        )
    }
}