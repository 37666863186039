<template>
  <div id='page'>
    
    <Sidenav id='sidenav-desktop'></Sidenav>

    <v-touch
      v-on:swipeleft='closeSidenav'>
      <Sidenav id='sidenav-mobile' 
        v-bind:class="{ 'sidenav-open': sidenavOpen, 'sidenav-closed': !sidenavOpen }"></Sidenav>  
    </v-touch>
    
    <v-touch id='content'
        v-on:swiperight='openSidenav' 
        v-on:swipeleft='closeSidenav'
        v-on:tap='closeSidenav'>
        <transition name='route-change'>
          <router-view></router-view>
        </transition>
    </v-touch>
  </div>
</template>

<script>
import Sidenav from './shared/sidenav/Sidenav.vue';
import EmployeeComponent from './employees/EmployeeComponent.vue';
import AuthService from '../auth/auth-service.js';
import AuthState from '../../states/AuthState.js';

export default {
  name: 'app-pages',
  components: {
    Sidenav,
    EmployeeComponent
  },
  data() {
    return {
      sidenavOpen: false,
    }
  },
  methods: {
    openSidenav() {
      this.sidenavOpen = true;
    },
    closeSidenav() {
      this.sidenavOpen = false;
    },
  },
  created() {
    // Refresh token every 15 minutes
    setInterval( function () {
      let authService = new AuthService();
      authService.refreshToken().then(
        response => {
          let tokenObj = authService.parseToken( response.body.token );
          authService.storeCookie( tokenObj );
          AuthState.token = tokenObj;
          AuthState.isLoggedIn = true;
        },
        error => {
          AuthState.isLoggedIn = false;
        }
      )
    }, 15000 * 60 );

  }

}
</script>

<style lang="less" src='./pages.less'></style>

