<template src='./LocationEditor.html'></template>

<script>
import StatusModal from '../../shared/modal/StatusModal.vue';
import PasswordConfirmModal from '../../shared/modal/PasswordConfirmModal.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import LocationService from '../LocationService';

export default {
    name: 'LocationEditor',
    components: { StatusModal, PasswordConfirmModal },
    props: [ 'location' ],
    data() {
        return {
            saveStatus: '',
            savingLocation: false,
            newLocation: {
                name: this.location.name || '',
                id: this.location.id || null
            },
            locationService: new LocationService()
        }
    },
    validations: {
        newLocation: {
            name: {
                required,
                minLength: minLength( 5 )
            }
        }
    },
    methods: {
        saveLocation() {
            this.$v.$touch();
            if ( this.$v.$invalid ) {
                this.saveStatus = "There are errors in your form. Please fill out all the fields correctly";
                this.$refs.StatusModal.show();
                return;
            }

            this.saveStatus = "Saving...";
            this.saveLocation = true;
            this.$refs.StatusModal.show();

            if ( this.newLocation.id == null ) {
                this.locationService.create( this.newLocation ).then(
                    _ => {
                        this.saveStatus = 'Location created successfully';
                        this.savingLocation = false;
                    },
                    error => {
                        this.handleSaveError( error );
                        this.savingLocation = false;
                    }
                );
            }
            else {
                this.locationService.update( this.newLocation, this.newLocation.id ).then(
                    _ => {
                        this.saveStatus = 'Location saved successfully';
                        this.savingLocation = false;
                    },
                    error => {
                        this.handleSaveError( error );
                        this.savingLocation = false;
                    }
                );
            }
        },
        deleteLocation() {
            this.$refs.ConfirmModal.show();
        },
        locationDeleteClosed( confirmed ) {
            if ( confirmed ) {
                this.locationService.delete( this.newLocation.id );
            }
        },
        handleSaveError( response ) {
            let errorMessage = "Server error. Please make sure all the fields are correct or try again.";
            if ( response.status === 400 ) {
                errorMessage = response.body.message || "Error. Please make sure all fields are correct";
            }
            this.saveStatus = errorMessage;
        },
    }
}
</script>

<style lang="less" scoped></style>

