<template>

<div>

    <div class='container'>

        <div id='title'>
            LR Thrift
        </div>

        <div v-if='timecardData' id='timecard-data'>
            <div v-if='timecardData.clock_out' class='timecard-title'>
                Clocked out 
            </div>
            <div v-else class='timecard-title'>
                Clocked in 
            </div>

            <div class='timecard-user'>
                {{ timecardData.user.name }}
            </div>
            <div class='timecard-usercode'>
                {{ timecardData.user.code }}
            </div>

            <div v-if='timecardData.clock_out' class='timecard-datetime'>
                <div class='timecard-date'>
                    {{ timecardData.clock_out.toFormat( 'LLLL dd, yyyy' ) }}
                </div>
                <div class='timecard-time'>
                    {{ timecardData.clock_out.toFormat( 'hh:mm a' ) }}
                </div>
            </div>
            <div v-else class='timecard-datetime'>
                <div class='timecard-date'>
                    {{ timecardData.clock_in.toFormat( 'LLLL dd, yyyy' ) }}
                </div>
                <div class='timecard-time'>
                    {{ timecardData.clock_in.toFormat( 'hh:mm a' ) }}
                </div>
            </div>

            <div v-if='timecardData.clock_out'>
                <div class='timecard-summary'>Time worked: {{ timecardData.hours_worked }}</div>
                <div class='timecard-summary'>Accumulated: {{ timecardData.accumulated_hours }}</div>
            </div>

            <div class='back-button-container'>
                <button @click='backToKeypad' class='back-button'>Back</button>
            </div>

        </div>

        <div v-else>
            <div id='location-select-container'>
                <select id='location-select' placeholder='Select a location' v-model="selectedLocation">
                    <option v-for='location in locations' :key='location.id' :value='location.id'>{{ location.name }}</option>
                </select>
            </div>

            <div id='user-input'>{{ input }}</div>

            <div id='error-container'>{{ error }}</div>

            <div id='keypad'>
                <div class='keypad-row'>
                    <button class='keypad-btn' @click='enterNumber(1)'>1</button>
                    <button class='keypad-btn' @click='enterNumber(2)'>2</button>
                    <button class='keypad-btn' @click='enterNumber(3)'>3</button>
                </div>
                <div class='keypad-row'>
                    <button class='keypad-btn' @click='enterNumber(4)'>4</button>
                    <button class='keypad-btn' @click='enterNumber(5)'>5</button>
                    <button class='keypad-btn' @click='enterNumber(6)'>6</button>
                </div>
                <div class='keypad-row'>
                    <button class='keypad-btn' @click='enterNumber(7)'>7</button>
                    <button class='keypad-btn' @click='enterNumber(8)'>8</button>
                    <button class='keypad-btn' @click='enterNumber(9)'>9</button>
                </div>

                <div class='keypad-row'>
                    <button class='keypad-btn' @click='clearNumber()'>Clear</button>
                    <button class='keypad-btn' @click='enterNumber(0)'>0</button>
                    <button class='keypad-btn' @click='submitNumber()'>Enter</button>
                </div>
                
            </div>
        </div>
    </div>
</div>

</template>

<script>
import Vue from 'vue'
import LocationService from '../locations/LocationService';
import { DateTime } from 'luxon';

export default {

    data: () => ({
        input: '',
        locationService: new LocationService(),
        locations: [],
        selectedLocation: null,
        error: '',
        timecardData: null,
    }),

    methods: {
        enterNumber( val ) {
            this.input += val;
        },

        clearNumber() {
            this.input = '';
        },

        submitNumber() {
            this.error = "";
            localStorage.setItem( 'selectedLocation', this.selectedLocation )

            if ( this.selectedLocation == null ) {
                this.error = "Please select a location before clocking in";
                return;
            }

            if ( this.input.length < 4 ) {
                this.error = "Please enter a valid code";
                return;
            }

            Vue.http.post( 'punch', {
                code: this.input,
                location_id: this.selectedLocation,
                time: DateTime.local().toFormat( "yyyy-LL-dd'T'HH:mm:ss" )
            } ).then(
                response => {
                    this.timecardData = response.body.data
                
                    this.timecardData.clock_in = new DateTime( this.timecardData.clock_in )
                    if ( this.timecardData.clock_out ) {
                        this.timecardData.clock_out = new DateTime( this.timecardData.clock_out )
                    }
                },
                err => {
                    console.log( err )
                    this.error = "A server error has occured. Please try again."
                }
            )
        },

        backToKeypad() {
            this.timecardData = null;
        }
    },

    created() {
        this.locationService.list().then(
            response => {
                this.locations = response.body.data;
                this.locationsLoaded = true;

                localStorage.setItem( 'locations', this.locations )

                if ( localStorage.getItem( 'selectedLocation' ) ) {
                    this.selectedLocation = localStorage.getItem( 'selectedLocation' )
                }
            }
        )
    }
    
}
</script>


<style lang="less">

body {
    background-color: rgb( 247, 247, 247 );
    font-size: 1.1em;
}

#title {
    font-size: 2.8em;
    text-align: center;
    margin-top: 40px;
}

#location-select-container {
    display: flex;

    #location-select {
        flex: 1;
        padding: 10px 20px;
        margin-top: 40px;
    }
}
    
#user-input {
    font-size: 2.8em;
    min-height: 3em;
    align-items: center;
    justify-content: center;
    display: flex;

    margin-top: 30px;
}

#error-container {
    color: rgb( 153, 30, 30 );
    font-size: 1.8em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

#keypad {
    
    .keypad-row {
        display: flex;
        width: 100%;

        .keypad-btn {
            flex: 1;
            border: 1px solid rgb( 240, 240, 240 );
            box-shadow: 0 2px 6px rgba( 150, 150, 150, 0.2 );
            font-size: 1.6em;
            align-items: center;
            text-align: center;

            padding: 40px;

            margin: 10px;

            background-color: white;
            user-select: none;
        }

        .keypad-btn:hover {
            background-color: rgb( 240, 240, 240 );
        }

        .keypad-btn:active {
            background-color: rgb( 220, 220, 220 );
        }
    }

}

#timecard-data {
    text-align: center;

    .timecard-title {
        font-size: 4em;
        margin: 20px 0;
        font-weight: bold;
    }

    .timecard-user {
        margin: 40px 0 8px 0;   
    }

    .timecard-usercode {
        margin-bottom: 40px;
    }

    .timecard-date {
        font-size: 2.5em;
        margin-bottom: 10px;
    }

    .timecard-time {
        font-size: 3em;
        margin-bottom: 20px;
    }

    .back-button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .back-button {
            min-width: 100px;
            padding: 8px 0;
            margin-top: 10px;
            background-color: #74b9ff;
        }

    }
}

</style>