<template src='./EmployeeReport.html'></template>


<script>

import WeekPicker from '../../../shared/weekpicker/WeekPicker';
import LocationService from '../../LocationService';
import BarLoader from '../../../shared/loaders/bar/BarLoader';
import AuthMixin from '../../../../../mixins/AuthMixin';
import DocumentDownloader from '../../../shared/document-downloader/DocumentDownloader.js';

export default {

    name: 'EmployeeReport',
    props: [ 'location_id' ],
    mixins: [ AuthMixin ],
    components: {
        WeekPicker,
        BarLoader
    },

    data() {
        return {
            locationService: new LocationService(),
            documentDownloader: new DocumentDownloader(),

            data: null,
            error: null,

            startDate: null,
            endDate: null,

            maxWorkTime: 0,
        }
    },

    methods: {
        dateChanged( e ) {
            this.endDate = e.to;
            this.startDate = e.from;
            
            this.data = null;
            this.error = null;

            this.locationService.employeeReport( this.location_id, this.startDate, this.endDate ).then(
                result => {
                    this.data = result.body
                },
                error => {
                    this.error = error.body.message || "Server error. Try again."
                }
            )
        },


        downloadLocationReport() {
            this.documentDownloader.getLocationReport( this.location_id, this.startDate, this.endDate );
        },

        downloadAllEmployeeWorkhours() {
            this.documentDownloader.getAllEmployeeTimesheets( this.location_id, this.startDate, this.endDate );
        },
        
        downloadEmployeeCodes() {
            this.documentDownloader.getEmployeeCodes( this.location_id );
        },
    },

    mounted() {
        this.maxWorkTime = +this.ApplicationSettings['MAX_WORK_TIME'];
    }


}

</script>


<style>

.hasError {
    color: #d50000 !important;
    font-weight: 600;
}

.exclamation-mark {
    font-size: 11px;
}


</style>