<template>
<div class="box">
    <div class="box-header">Add Location</div>
    <div class="box-content content-padding">
        <LocationEditor :location='location'/>
    </div>
</div>
</template>

<script>
import LocationEditor from '../editor/LocationEditor.vue';

export default {
    name: 'AddLocation',
    components: {
        LocationEditor
    },
    data() {
        return {
            location: {},
        }
    },
    methods: {

    },
}
</script>

<style lang="less" scoped></style>

