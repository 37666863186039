<template src='./EmployeeList.html'></template>
    
<script>
import EmployeeService from '../EmployeeService.js';
import Paginator from '../../shared/paginator/Paginator.vue';
import BarLoader from '../../shared/loaders/bar/BarLoader.vue';
import LocationService from '../../locations/LocationService.js';
import AuthMixin from '../../../../mixins/AuthMixin.js';
import _ from 'lodash'

export default {
    name: 'EmployeeList',
    mixins: [ AuthMixin ],
    components: {
        Paginator,
        BarLoader
    },
    data() {
        return {
            users: [],
            filters: {
                name: '',
                login_name: '',
                code: '',
                location_id: '',
                deleted_only: false,
                applicable_insurance: false,
            },
            total: 0,
            current_page: 1,
            last_page: 0,
            employeeService: new EmployeeService,
            locationService: new LocationService,
            locations: [],
            isLoading: true
        }
    },
    methods: {
        getUsers() {
            this.users = [];
            this.isLoading = true;
            this.employeeService.list( this.filters, this.current_page ).then(
                response => {
                    let body = response.body;
                    this.users = body.data;
                    this.total = body.meta.total;
                    this.current_page = body.meta.current_page;
                    this.last_page = body.meta.last_page;
                    this.isLoading = false;
                }
            )
        },
        filterUpdated: _.throttle( function() {
            this.current_page = 1; 
            this.getUsers(); 
        }, 500 ),
        setPage( pageNum ) {
            this.current_page = pageNum;
            this.getUsers(); 
        }
    },
    created() {
        this.getUsers();
        if ( this.IsAdmin() ) {
            this.locationService.list().then(
                response => this.locations = response.body.data,
                error => console.log( error )
            );
        }
        else {
            this.locations = [ this.AuthenticatedUser.location ];
            this.filters.location_id = this.AuthenticatedUser.location.id;
        }
    },
}
</script>

<style lang="less" scoped>

.working-indicator {
    color: #9CCC65; 
    margin-right: 10px;
    font-size: 9px;
}

.working-status {
    font-style: italic;
    font-size: 12px;
    color: #546E7A;
    font-family: 'Raleway', sans-serif;
}

.highlight-blue {
    font-weight: bold;
    color: #311B92;
}
</style>
