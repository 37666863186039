<template src='./Sidenav.html'></template>

<script>
import AuthState from '../../../../states/AuthState.js';
import AuthService from '../../../auth/auth-service.js';
import AuthMixin from '../../../../mixins/AuthMixin.js';

export default {
    name: 'Sidenav',
    mixins: [ AuthMixin ],
    methods: {
        logout() {
            let authService = new AuthService();
            authService.logout();
            AuthState.isLoggedIn = false;
            this.$router.push( '/login' );
        }
    },
    computed: {
        userName() {
            return AuthState.token ? AuthState.token.payload.user.name : 'Unauthorized';
        }
    }
}
</script>

<style src='./Sidenav.less' lang="less" scoped></style>

