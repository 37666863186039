<template>
    <router-view></router-view>
</template>

<script lang="ts">
import Vue from 'vue'
import EmployeeList from './list/EmployeeList.vue';

export default Vue.extend({
    name: 'EmployeeComponent',
    components: {
        EmployeeList
    }    
})
</script>
