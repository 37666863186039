import Vue from 'vue';
import { DateTime } from 'luxon';

export default class {
    
    getUserLeaveDays( id, from, to ) {
        let url = `vacations/employee/${id}`;
        if ( from instanceof DateTime && to instanceof DateTime ) {
            url += `?from=${from.toISODate()}&to=${to.toISODate()}`;
        }
        else if ( from instanceof DateTime ) {
            url += `?from=${from.toISODate()}`;
        }
        else if ( to instanceof DateTime ) {
            url += `?to=${to.toISODate()}`;
        }
        return Vue.http.get( url );
    }

    addUserLeaveDays( id, from, to, as_work_hours, comment, type ) {
        return Vue.http.post( `vacations/employee/${id}/add`, {
            from: from,
            to: to,
            as_work_hours: as_work_hours,
            comment: comment,
            type: type
        } );
    }

    deleteVacationDays( id ) {
        return Vue.http.delete( `vacations/${id}` );
    }
}