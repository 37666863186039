<template>
<div class='box'>

    <StatusModal ref='StatusModal'
        :message='dateSaveMessage'
        :isLoading='dateSaveLoading'></StatusModal>
  
    <div class="box-header">
        Vacations and absent days
    </div>
    <div class='box-content content-padding'>
        <div v-if='user.deleted_at == null'>
            <form class='filter-form' id='addDaysForm'>
                <div class='form-row'>
                    <label class=''>Add date: </label>
                    <input ref='datepicker_from' class='form-control' type='text' placeholder='From'>
                    <input ref='datepicker_to' class='form-control' type='text' placeholder='To'>
                    <input class='form-control' type='text' placeholder='Hours' v-model='as_work_hours'>
                </div>

                <div class='form-row'>
                    <label>Comment: </label>
                    <textarea class='form-control' rows='1' placeholder='Add an optional comment to vacation/absent day' v-model='comment'></textarea>
                </div>

                <div class='form-row'>
                    <div class='btn btn-primary' v-on:click='addDate'>Add as vacation</div>
                    <div class='btn btn-primary' 
                        v-on:click='() => addDate( "absent" )'
                        style='margin-left: 10px'>Add as absent day</div>
                </div>
            </form>
        </div>
        <form class='filter-form' id='addDaysForm'>
            <div class='form-row'>
                <label class=''>Show vacations from: </label>
                <select class='form-control' v-model='showVacationsTimePeriod' v-on:change='getLeaveDays'>
                    <option value='1'>1 Month Ago</option>
                    <option value='2'>2 Months Ago</option>
                    <option value='3'>4 Months Ago</option>
                    <option value='6'>6 Months Ago</option>
                    <option value='12'>12 Months Ago</option>
                    <option value='24'>24 Months Ago</option>
                    <option value='0'>All time</option>
                </select>
            </div>
        </form>
        <div v-if='!isLoading'>
            <div class='table'>
            <table class='table table-hover'>
                <thead>
                    <th><i class="far fa-sticky-note"></i></th>
                    <th>From</th>
                    <th>To</th>
                    <th>Duration</th>
                    <th>Type</th>
                    <th>Equals to work hours</th>
                    <th v-if='IsAdmin()'></th>
                </thead>
                <tbody>
                <tr v-for='( day, index ) in leaveDays' :key='index'>
                    <td>
                        <span class='pill pill-yellow' v-if='day.comment != null && day.comment != ""' v-on:click.stop='showComment( day.comment )'>
                            <i class="fas fa-exclamation"></i>
                        </span>
                    </td>
                    <td>{{ day.from }}</td>
                    <td>{{ day.to }}</td>
                    <td>{{ day.duration }} days</td>
                    <td>
                        <span class='pill pill-blue' v-if='day.type == "vacation"'>
                            Vacation
                        </span>
                        <span class='pill pill-red' v-if='day.type == "absent"'>
                            Absent
                        </span>
                    </td>
                    <td>{{ day.as_work_hours }} hours</td>
                    <td v-if='IsAdmin()'>
                        <span 
                            class='far fa-trash-alt delete-icon' 
                            v-on:click.stop="openVacationDeleteDialog( day.id )">
                        </span>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class='table-footer'>
                <div class="footer-info">Total: {{ totalOffHours }}</div>
            </div>
        </div>
    </div>

    <div v-if='isLoading'>
        <BarLoader></BarLoader>
    </div>
  </div>

<Modal ref='ViewCommentModal'>
    <div slot='header'>View note</div>
    <div slot='content' >
        <p>{{ viewingComment }}</p>
    </div>
    <div slot='footer' style='display: flex'>
        <div class='btn btn-white' v-on:click="$refs.ViewCommentModal.close()">Cancel</div>
    </div>
</Modal>

<PasswordConfirmModal ref='ConfirmModal'
    message='Are you sure you want to delete this vacation?'
    v-on:close='vacationDeleteClosed'
></PasswordConfirmModal>

</div>
</template>

<script>
import BarLoader from '../loaders/bar/BarLoader.vue';
import EmployeeService from '../../employees/EmployeeService.js';
import StatusModal from '../modal/StatusModal';
import LeaveDaysService from './LeaveDaysService.js';
import flatpickr from 'flatpickr';
import _ from 'lodash';
import { DateTime } from 'luxon';
import AuthMixin from '../../../../mixins/AuthMixin';
import PasswordConfirmModal from '../modal/PasswordConfirmModal';
import Modal from '../modal/Modal';

export default {
    name: "LeaveDaysManager",
    props: [ 'user' ],
    components: {
        BarLoader,
        StatusModal,
        PasswordConfirmModal,
        Modal
    },
    mixins: [ AuthMixin ],

    data() {
        return {
            id: null,
            leaveDaysService: new LeaveDaysService(),
            isLoading: true,
            leaveDays: [],

            fromDate: null,
            toDate: null,
            as_work_hours: null,
            comment: '',

            dateSaveMessage: '',
            dateSaveLoading: false,

            // User can select from how many months ago should vacations be shown
            showVacationsTimePeriod: 12,

            // Temporary store the holiday user is trying to delete
            deletingVacation: 0,

            // Used in modal, to show the comment of the currently selected vacation
            viewingComment: '',
        }
    },
    methods: {
        addDate( type ) {

            if ( type != "absent" ) type = "vacation"

            this.dateSaveLoading = true;
            this.dateSaveMessage = "Saving date...";
            this.$refs.StatusModal.show();

            if ( this.fromDate == null || this.toDate == null ) {
                this.dateSaveMessage = "Please select a start and end date";
                this.dateSaveLoading = false;
                return;
            }

            else if ( this.toDate < this.fromDate ) {
                this.dateSaveMessage = "Error: end date is before the start date";
                this.dateSaveLoading = false;
                return;
            }

            if ( Number.parseFloat( this.as_work_hours ) == NaN ) {
                this.dateSaveMessage = "Error: the entered hours are not in correct format. Make sure you use the decimal format, like 1.23";
                this.dateSaveLoading = false;
                return;
            }

            this.leaveDaysService.addUserLeaveDays(
                this.id,
                this.fromDate.toISODate(),
                this.toDate.toISODate(),
                this.as_work_hours,
                this.comment,
                type
            ).then(
                _ => {
                    this.dateSaveLoading = false;
                    this.dateSaveMessage = type == 'vacation' ? "Vacation saved successfully" : "Absent day saved succesfully";
                    this.getLeaveDays();
                    // Clear the initial form
                    this.as_work_hours = null;
                    this.comment = '';
                },
                _ => {
                    this.dateSaveLoading = false;
                    this.dateSaveMessage = type == 'vacation' ? "Error saving vacation" : "Error saving absent days";
                }
            )
        },
        getLeaveDays() {
            let startDate = null;

            if ( +this.showVacationsTimePeriod != 0 ) {
                startDate = DateTime.local().minus({ months: this.showVacationsTimePeriod });
            }
            
            this.leaveDaysService.getUserLeaveDays( this.id, startDate )
            .then(
                response => {
                    this.leaveDays = response.body.data;
                    this.isLoading = false;
                },
                _ => {}
            )
        },

        openVacationDeleteDialog( vacation ) {
            this.deletingVacation = vacation;
            this.$refs.ConfirmModal.show();
        },

        vacationDeleteClosed( success ) {
            if ( !success ) return;
            
            this.leaveDaysService.deleteVacationDays( this.deletingVacation )
            .then(
                response => {
                    this.getLeaveDays();
                },
                _ => {}
            )
        },
        showComment( comment ) {
            this.viewingComment = comment;
            this.$refs.ViewCommentModal.show()
        }
    },
    created() {
        this.id = this.user.id;
        this.getLeaveDays();
    },
    mounted() {
        flatpickr( this.$refs.datepicker_to, {
            dateFormat: 'm/d/Y',
            onChange: ( dates ) => {
                this.toDate = DateTime.fromJSDate( dates[0] );
            },
            minDate: DateTime.local().minus({ days: 14 }).toJSDate()
        } );
        flatpickr( this.$refs.datepicker_from, {
            dateFormat: 'm/d/Y',
            onChange: ( dates ) => {
                this.fromDate = DateTime.fromJSDate( dates[0] );
            },
            minDate: DateTime.local().minus({ days: 14 }).toJSDate()
        } );
    },
    computed: {
        totalOffHours() {
            return _.sum( _.map( this.leaveDays, ( r ) => r.duration ) )
        }
    }
}
</script>

<style>
#addDaysForm {
    margin-bottom: 15px;
}

#work-stats {
    font-size: 14px;
    margin: 25px 0 5px;
    color: rgb( 80, 80, 80 );
    font-family: 'Roboto', sans-serif;
}

.delete-icon {
    color: #e57373;
    padding: 5px;
    font-size: 13px;
}

.delete-icon:hover {
    font-size: 12px;
    color: white;
    background-color: #e57373;
    border-radius: 800px;
    cursor: pointer;
}
</style>
