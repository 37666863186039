import Vue from 'vue';

export default class {
    list() {
        return Vue.http.get( 'location/list' );
    }

    get( id ) {
        return Vue.http.get( `location/${id}` );
    }

    create( data ) {
        return Vue.http.put( 'location/add', data );
    }

    update( data, id ) {
        return Vue.http.patch( `location/update/${id}`, data );
    }

    delete( id ) {
        return Vue.http.delete( `location/${id}` );
    }

    employeeReport( id, from, to ) {
        return Vue.http.get( `location/report/${id}?from=${from.toISODate()}&to=${to.toISODate()}` );
    }
}