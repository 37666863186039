<template>
<div id='modal-container'>
    <transition name='backdrop'>
        <div id='backdrop'
            v-if='visible'
            v-on:click='close'>
        </div>
    </transition>

    <transition name='modal'>
        <div id='modal' v-if='visible'>
            <div id='header'>
                <slot name='header'></slot>
            </div>
            <div id='content'>
                <slot name='content'></slot>
            </div>
            <div id='footer'>
                <slot name='footer'></slot>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import ModalMixin from './ModalMixin.vue';

export default {
    name: "Modal",
    mixins: [ ModalMixin ],
}

</script>

<style lang="less" scoped src='./Modal.less'></style>
