import LocationList from './list/LocationList.vue';
import AddLocation from './add/AddLocation.vue';
import ViewLocation from './view/ViewLocation.vue';
import AuthState from '../../../states/AuthState.js';

export default [
    { path: '', redirect: '/location/list' },
    { 
        path: 'list', 
        component: LocationList,
        beforeEnter( to, from, next ) {
            if ( AuthState.token.payload.user.type === 'ADMIN' ) {
                next();
            }
            else if ( AuthState.token.payload.user.type === 'LOCATION_ADMIN' ) {
                next( '/location/view/' + AuthState.token.payload.user.location.id )
            }
            else {
                next({ name: '403' });
            }
        } 
    },
    { 
        path: 'add', 
        component: AddLocation,
        beforeEnter( to, from, next ) {
            if ( AuthState.token.payload.user.type === 'ADMIN' ) {
                next();
            }
            else {
                next({ name: '403' });
            }
        } 
    },
    { path: 'view/:id', component: ViewLocation },
]