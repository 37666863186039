<template>
<div id='container'>
    <div id='error-text'>Error:</div>
    <router-view id='error-message'></router-view>
    <div id='go-back' v-on:click='back()'>Go Back</div>
    <div id='go-home' v-on:click='home()'>Go Home</div>
</div>
</template>

<script>
export default {
    name: 'Status',
    methods: {
        back() {
            this.$router.go( -2 );
        },
        home() {
            this.$router.push( '/' );
        }
    }
}
</script>

<style lang='less' scoped>
@backgroundColor: rgb(234, 32, 39);
#container {
    background: linear-gradient( 254deg, @backgroundColor, rgb(111, 30, 81));
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
        font-family: 'Quicksand', sans-serif;

    #error-text {
        color: white;
        font-size: 2em;
    }
    #error-message {
        font-size: 2.5em;
        color: white;
    }

    #go-back {
        margin-top: 80px;
        color: white;
        
    }

    #go-home {
        margin-top: 15px;
        color: white;
    }
}
</style>
