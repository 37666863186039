<template>
  <div id='page' class='justify-content-center d-flex align-items-start'>

    <div id="login-container">
      <div id="title">
        Southern Thrift
      </div>

      <form id='form' v-on:submit='login()'>
        <div id='error-message' v-if="message != ''">
          {{ message }}
        </div>

        <div class='auth-form-group'>
          <label>Login name or code</label>
          <input type='text' class='input' placeholder='Login name or code' v-model='name' autofocus>
        </div>
        <div class='auth-form-group'>
          <label>Password</label>
          <input type='password' class='input' placeholder='Password' v-model='password' v-on:keyup.enter='login()'>
        </div>

        <button v-if='!isLoading' type='button' class='btn btn-primary' id='submit-btn' v-on:click='login()'>Log in</button>
        <BarLoader v-if='isLoading'></BarLoader>
      </form>
    </div>
  </div>
</template>

<script>/* eslint-disable */
import AuthService from './auth-service.js';
import AuthState from '../../states/AuthState.js';
import Vue from 'vue';
import BarLoader from '../pages/shared/loaders/bar/BarLoader.vue';

export default {
  name: 'app-auth',
  components: {
    BarLoader
  },
  data() { 
    return {
      name: '',
      password: '',
      authService: new AuthService(),
      message: '',
      isLoading: false,
    } 
  },
  created() {
    let token = this.authService.loginFromCookie();
    if ( token !== null ) {
      this.setAuthState( token );
      this.redirect();
    }
  },
  methods: {
    login() {
      this.isLoading = true;
      this.authService.login({
        'name': this.name,
        'password': this.password
      }).then(
        result => {
          let token = this.authService.parseToken( result.body.token );
          this.authService.storeCookie( token );
          // Set authentication state
          this.setAuthState( token );

          this.redirect();
          
        },
        error => {
          this.message = error.body.message || "Server error, please try again"
        }
      )
      .finally(
        () => this.isLoading = false
      );
    },

    setAuthState( token ) {
      AuthState.token = token;
      AuthState.isLoggedIn = true;
      // Set a global http setting so all requests will carry an authorization header
      Vue.http.headers.common['Authorization'] = token.tokenString;
    },

    redirect() {
      // Redirect to the page user tried to access or to home
      if ( this.$route.query.redirect !== undefined ) {
        this.$router.push( this.$route.query.redirect );
      }
      else {
        this.$router.push( '/' );
      }
    }
  }
}
</script>

<style lang="less" scoped src='./auth.less'>

</style>

