<template src='./Timecard.html'>

</template>


<script>
import EmployeeService from '../../employees/EmployeeService';
import { DateTime, Interval } from 'luxon';
import WeekPicker from '../weekpicker/WeekPicker';
import AuthMixin from '../../../../mixins/AuthMixin';
import Modal from '../../shared/modal/Modal';
import PasswordConfirmModal from '../../shared/modal/PasswordConfirmModal';
import StatusModal from '../../shared/modal/StatusModal';
import { required, between, minLength } from 'vuelidate/lib/validators';
import flatpickr from 'flatpickr';
import BarLoader from '../loaders/bar/BarLoader';
import DocumentDownloader from '../document-downloader/DocumentDownloader';
import CreateTimecardModal from './create-timecard-modal/CreateTimecardModal';
import EmployeeStatistics from './employee-stats/EmployeeStats';
import AddNoteModal from './add-note-modal/AddNoteModal';

export default {
    name: 'Timecard',
    props: [ 'date', 'showDatePicker', 'user' ],

    components: {
        WeekPicker,
        Modal,
        StatusModal,
        BarLoader,
        CreateTimecardModal,
        PasswordConfirmModal,
        EmployeeStatistics,
        AddNoteModal
    },

    mixins: [ AuthMixin ],
    
    data() { 
        return {
            isLoading: true,
            employeeService: new EmployeeService(),
        
            formattedTimecards: [],
            endDate: DateTime.local(),
            startDate: DateTime.local(),

            error: null,

            editingDate: {
                id: 0,
                editing: 'clock_in',
                date: 0,
                hour: 0,
                minute: 0,
                comment: '',
            },

            saveStatus: '',
            saving: false,

            selectedTimecard: null,

            documentDownloader: new DocumentDownloader(),

            fullReportDate: DateTime.local().year
        }
    },

    validations: {
        editingDate: {
            date: {
                required
            },
            hour: {
                required,
                between: between( 0, 23 )
            },
            minute: {
                required,
                between: between( 0, 59 )
            },
            comment: {
                required,
                minLength: minLength( 5 )
            }
        }
    },

    methods: {
        getTimecards() {
            this.isLoading = true;
            this.employeeService.getUserTimecards( 
                this.user.id,
                this.startDate, this.endDate,
            )
            .then(
                response => this.formatTimecards( response.body.data ),
                error => {
                    this.error = "Server error. Try again"
                }
            )
            .finally( () => this.isLoading = false )
        },

        dateChanged( e ) {
            this.endDate = e.to;
            this.startDate = e.from;
            this.getTimecards();
        },

        formatTimecards( timecards ) {

            this.formattedTimecards = [{
                date: this.startDate.startOf( 'day' ), 
                timecards: []
            }];
            
            // First, group all timecards into separate dates
            for ( let i = 0; i < timecards.length; i++ ) {
                let card = {
                    id: timecards[i].id,
                    clock_in: DateTime.fromISO( timecards[i].clock_in, {setZone: true} ),
                    clock_out: timecards[i].clock_out == null ? null : DateTime.fromISO( timecards[i].clock_out, {setZone: true} ),
                    original_clock_in: timecards[i].original_clock_in == null ? null : DateTime.fromISO( timecards[i].original_clock_in, {setZone: true} ),
                    original_clock_out: timecards[i].original_clock_out == null ? null : DateTime.fromISO( timecards[i].original_clock_out, {setZone: true} ),
                    comment: timecards[i].comment,
                    edited_on: timecards[i].edited_on == null ? null : DateTime.fromISO( timecards[i].edited_on ),
                    time_worked: timecards[i].time_worked,
                    hours_worked: timecards[i].hours_worked,
                    time_worked_today: 0,                
                    accumulated: timecards[i].accumulated,
                    accumulated_hours: timecards[i].accumulated_hours,
                    edited_by: timecards[i].edited_by == null ? null : timecards[i].edited_by.name,
                    note: timecards[i].note
                }
                
                if ( +this.formattedTimecards[this.formattedTimecards.length - 1].date != +card.clock_in.startOf( 'day' ) ) {
                    this.formattedTimecards.push({
                        date: card.clock_in.startOf( 'day' ),
                        timecards: [ card ]
                    });
                }
                else {
                    this.formattedTimecards[this.formattedTimecards.length - 1].timecards.push(
                        card
                    );
                }
            }

            // Now calculate work duration for each timecard
            for ( let i = 0; i < this.formattedTimecards.length; i++ ) {
                let accumulated_time = 0;
                for ( let d = 0; d < this.formattedTimecards[i].timecards.length; d++ ) {
                    accumulated_time += this.formattedTimecards[i].timecards[d].hours_worked;
                    this.formattedTimecards[i].timecards[d].time_worked_today = accumulated_time;
                }
            }
        },

        accumulated( accumulated ) {
            return Math.min( accumulated, +this.ApplicationSettings['MAX_WORK_TIME'] ) .toFixed( 2 )
        },

        overtime( accumulated ) {
            let val = Math.max( ( accumulated ) - (+this.ApplicationSettings['MAX_WORK_TIME']), 0 ).toFixed( 2 )
            if ( val == 0 ) return null;
            return val;
        },

        changeTime( card, editing ) {
            this.editingDate.id = card.id;
            this.editingDate.editing = editing;
            if ( editing == 'clock_in' ) {
                var clock_in = card.clock_in || DateTime.local();
                this.editingDate.hour = clock_in.hour;
                this.editingDate.minute = clock_in.minute;
                this.editingDate.date = clock_in;
            }
            if ( editing == 'clock_out' ) {
                var clock_out = card.clock_out || DateTime.local();
                this.editingDate.hour = clock_out.hour;
                this.editingDate.minute = clock_out.minute;
                this.editingDate.date = clock_out;
            }
            this.$refs.ChangeTimeModal.show();

            setTimeout( () => {
                flatpickr( this.$refs.datepicker, {
                    dateFormat: 'm/d/Y',
                    onChange: ( dates ) => {
                        this.editingDate.date = DateTime.fromJSDate( dates[0] );
                    },
                    defaultDate: this.editingDate.date.toJSDate()
                } );
            })
        },

        addTime() {
            this.$refs.CreateTimecardModal.show();
        },

        saveTime() {
            this.$v.$touch()
            if ( this.$v.$invalid ) {
                return;
            }

            let changes = {
                comment: this.editingDate.comment,
            }
            
            changes[this.editingDate.editing] = this.editingDate.date.set({
                hour: this.editingDate.hour,
                minute: this.editingDate.minute
            });

            this.$refs.ChangeTimeModal.close();
            this.$refs.StatusModal.show();
            this.saveStatus = "Saving changes...";
            this.saving = true;

            this.employeeService.updateUserTimecard( this.editingDate.id, changes )
            .then(
                _ => {
                    this.getTimecards();
                    this.saveStatus = "Saved";
                },
                error => {
                    this.saveStatus = error.body.message || "Error. Make sure all the fields are correct and try again";
                }
            ).finally(
                () => this.saving = false
            )
        },

        showTimecardInfo( timecard ) {
            this.selectedTimecard = timecard;
            this.$refs.InfoModal.show();
        },

        download() {
            this.documentDownloader.getUserTimesheet( this.user.id, this.startDate, this.endDate );
        },

        downloadYearReport() {
            console.log( this.fullReportDate )
            let startDate = DateTime.fromObject({
                year: +this.fullReportDate,
                month: 1, day: 1, hour: 0, minute: 0, second: 1 
            });

            let endDate = startDate.plus({ year: 1 }).minus({ minute: 1 })

            this.documentDownloader.getUserTimesheet( this.user.id, startDate, endDate );
        },

        timecardDeleteClosed( confirmed ) {
            if ( !confirmed ) return;

            this.$refs.StatusModal.show();
            this.saveStatus = "Deleting timecard...";
            this.saving = true;

            this.employeeService.deleteUserTimecard( this.selectedTimecard.id ).then(
                _ => {
                    this.getTimecards();
                    this.saveStatus = "Saved";
                },
                error => {
                    this.saveStatus = error.body.message || "Error. Could not delete timecard";
                }
            ).then(
                () => this.saving = false 
            );
        }
    },

    created() {
        this.endDate = this.date || DateTime.local().endOf( 'week' );
        this.startDate = this.endDate.startOf( 'week' );
        this.getTimecards()
    },
}
</script>


<style lang="less" scoped>

td {
    text-align: left;
    padding: 15px 0;
    font-family: 'Quicksand';
    // font-family: 'Raleway';
    // font-family: 'Roboto';
    font-size: 14px;
}

.edit-icon {
    color: #90A4AE;
    margin-left: 5px;
    padding: 5px;
    font-size: 13px;
}

.edit-icon:hover {
    color: white;
    background-color: #90A4AE;
    border-radius: 800px;
    cursor: pointer;
}

.time-edited {
    font-weight: 600;
    font-family: 'Quicksand';
    color: #6200EA;
}

.hasError {
    color: #d50000 !important;
    font-weight: 600;
    font-family: 'Quicksand';
}

.exclamation-mark {
    font-size: 11px;
}


.add-note-icon {
    color: rgb( 180, 180, 200 );
    padding: 3px 6px;
    border-radius: 100px;
    font-size: 16px;
}

.add-note-icon:hover {
    color: rgb( 100, 100, 140 );
    box-shadow: 0 0 8px rgba( 50, 50, 50, 0.2 );
}

</style>
