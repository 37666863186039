<template>
<div>
<Modal ref='AddNoteModal'>
    <div slot='header'>Add note to a timecard</div>
    <div slot='content' >
        <form class='filter-form'>
            <!-- Comment -->
            <div class="form-row" style='margin-top: 0'>
                <label>Additional remarks: </label>
                <textarea class='form-control' placeholder="Enter a custom note related to this timecard" v-model.trim='$v.editingDate.note.$model'
                    v-bind:class="{'form-control-error': $v.editingDate.note.$error }" >
                </textarea>
            </div>

            <div v-if='$v.editingDate.note.$dirty'>
                <div class="error-msg" v-if='!$v.editingDate.note.required'>Note is required</div>
            </div>
            
        </form>
    </div>
    <div slot='footer' style='display: flex'>
        <div class='btn btn-primary' v-on:click='saveNote()' style='margin-right: 15px'>Save</div>
        <div class='btn btn-white' v-on:click="$refs.AddNoteModal.close()">Cancel</div>
    </div>
</Modal>

<StatusModal ref='StatusModal'
    :message='saveStatus'
    :isLoading='saving'>
</StatusModal>

</div>
</template>

<script>

import { required } from 'vuelidate/lib/validators';
import Modal from '../../../shared/modal/Modal';
import flatpickr from 'flatpickr';
import { DateTime } from 'luxon';
import EmployeeService from '../../../employees/EmployeeService';
import StatusModal from '../../../shared/modal/StatusModal';

export default {
    
    props: [ 'user' ],

    components: {
        Modal, StatusModal
    },

    data() {
        return {
            editingDate: {
                id: 0,
                note: '',
            },

            employeeService: new EmployeeService(),

            saving: false,
            saveStatus: '',
        }
    },

    validations: {
        editingDate: {
            note: { required }
        }
    },

    methods: {
        show( id, note ) {
            this.editingDate.id = id;
            this.editingDate.note = note || '';
            this.$refs.AddNoteModal.show();
        },

        saveNote() {
            this.saving = true;
            this.saveStatus = "Saving timecard...";
            this.$refs.AddNoteModal.close();
            this.$refs.StatusModal.show();
            console.log( "saving " + this.editingDate.id )
            this.employeeService.setTimecardNote( this.editingDate.id, this.editingDate.note ).then(
                _ => {
                    this.saveStatus = "Saved";
                    this.$emit( 'saved' );
                },
                error => {
                    this.saveStatus = error.body.message || "Error. Make sure all the fields are correct and try again";
                }
            ).finally( () => this.saving = false );
        },
    },


}
</script>


<style>

</style>