<script>
export default {
    data() {
        return {
            visible: false
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        close() {
            this.$emit( 'close', null );
        }
    },
    created() {
        this.$on( 'close', function() {
            this.visible = false;
        } );
    }
}
</script>
