<template>
<div>
<Modal ref='StatModal'>
    <div slot='header'>Work statistics</div>
    <div slot='content' >
        <BarLoader v-if='isLoading' message='Loading work statistics...'></BarLoader>
        <div v-if='!isLoading'>
            <ul class='table-list' style='margin-bottom: 20px'>
                <li><b>Date employed: </b> {{ date_employed.toFormat( 'LLLL d, y, (LL/dd/y)' ) }}</li>
                <li><b>Average hours worked per week:</b> {{ average_hours_worked_week.toFixed( 2 ) }}</li>
            </ul>

            <div v-if='IsAdmin() && user.insurance_status != "NOT_APPLICABLE"'>
                This employee is applicable for health insurance.
                <form class='filter-form' style='margin-top: 15px'>
                    <div class='form-row'>
                        <label class=''>Insurance: </label>
                        <select class='form-control' v-model='insurance_status'>
                            <option value='APPLICABLE'>Applicable</option>
                            <option value='RECEIVING'>Receiving</option>
                            <option value='REFUSED'>Refused</option>
                        </select>
                    </div>
                    <div v-if='!isInsuranceLoading'>
                        <div class='btn btn-primary' style='display: inline-block' v-on:click='saveInsuranceStatus'>Save</div>
                        <div id='insuranceStatusMessage' style='margin-top: 12px'>{{ insuranceSaveStatus }}</div>
                    </div>
                    <div v-else>
                        <BarLoader message='Saving changes...'></BarLoader>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div slot='footer' style='display: flex'>
        <div class='btn btn-white' v-on:click="$refs.StatModal.close()">Close</div>
    </div>
</Modal>
</div>
</template>


<script>

import Modal from '../../modal/Modal';
import BarLoader from '../../loaders/bar/BarLoader';
import EmployeeService from '../../../employees/EmployeeService';
import { DateTime } from 'luxon';
import AuthMixin from '../../../../../mixins/AuthMixin';

export default {
    name: 'EmployeeStatistics',
    props: [ 'user' ],
    mixins: [ AuthMixin ],
    components: { Modal, BarLoader },

    data() {
        return {
            isLoading: true,
            date_employed: null,
            average_hours_worked_week: 0,

            error: '',

            employeeService: new EmployeeService(),
            insurance_status: '',
            isInsuranceLoading: false,
            insuranceSaveStatus: ''
        }
    },

    methods: {
        getWorkStats() {
            this.employeeService.getWorkStatistics( this.user.id ).then(
                res => {
                    this.date_employed = DateTime.fromISO( res.body.date_employed );
                    this.average_hours_worked_week = res.body.average_hours_worked_week
                },
                err => {
                    this.error = "Could not load employee statistics. Try again later"
                }
            ).finally(
                () => this.isLoading = false
            )
        },

        saveInsuranceStatus() {
            this.isInsuranceLoading = true;
            this.insuranceSaveStatus = "";
            this.employeeService.setInsuranceStatus( this.user.id, this.insurance_status ).then(
                () => { this.insuranceSaveStatus = "Changes saved" },
                () => { this.insuranceSaveStatus = "Error saving. Try again" }
            ).finally(
                () => this.isInsuranceLoading = false
            );
        },

        show() {
            this.$refs.StatModal.show();
            if ( this.IsAdmin() ) this.insurance_status = this.user.insurance_status;
            this.getWorkStats()
        }
    },

}
</script>


<style lang="less" scoped>
</style>
