<template>
<div id='modal-container'>
    <transition name='backdrop'>
        <div id='backdrop'
            v-if='visible'
            v-on:click='close'>
        </div>
    </transition>

    <transition name='modal'>
        <div id='modal' v-if='visible'>
            <div id='header'>
                {{ title || "Status" }}
            </div>
            <div id='content'>
                {{ message }}
            </div>
            <div id='footer'>
                <button type='button' 
                    class='btn btn-sm btn-primary'
                    v-if='!isLoading'
                    v-on:click='close()'>Close</button>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import Modal from './Modal.vue';

export default {
    extends: Modal,
    name: 'StatusModal',
    props: [ 
        'isLoading', 
        'message',
        'loadingMessage',
        'title' ],
    components: {
        Modal
    },  
    data() {
        return {}
    },
    methods: {
        close() {
            if ( !this.isLoading ) {
                this.$emit( 'close', null );
            }
        }
    },
    created() {
    }
}
</script>

<style lang="less" scoped>
    @import './Modal.less';

    #content {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
</style>

