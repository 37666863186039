<template src='./ViewLocation.html'></template>

<script>
import LocationEditor from '../editor/LocationEditor.vue';
import BarLoader from '../../shared/loaders/bar/BarLoader.vue';
import LocationService from '../LocationService';
import AuthMixin from '../../../../mixins/AuthMixin';
import EmployeeReport from './employee-report/EmployeeReport';

export default {
    name: 'ViewLocation',
    mixins: [ AuthMixin ],
    components: {
        LocationEditor,
        BarLoader,
        EmployeeReport,
    },
    data() {
        return {
            location: null,
            location_id: this.$route.params['id'],
            locationService: new LocationService(),
        }
    },
    methods: {
    },
    created() {
        this.locationService.get( this.location_id ).then(
            response => this.location = response.body.data,
            error => {
                if ( error.status === 404 ) {
                    this.$router.push({ name: '404' });
                }
                else if ( error.status === 403 ) {
                    this.$router.push({ name: '403' })
                }
            }
        );
    },
    mounted() {
    }
}
</script>