<template src='./LocationList.html'>
</template>

<script>
import LocationService from '../LocationService.js';
import BarLoader from '../../shared/loaders/bar/BarLoader.vue';

export default {
    name: 'LocationList',
    components: { BarLoader },
    data() {
        return {
            locations: [],
            locationService: new LocationService(),
            locationsLoaded: false
        }
    },
    methods: {
        getLocations() {
            this.locationsLoaded = false;
            this.locationService.list().then(
                response => {
                    this.locations = response.body.data;
                    this.locationsLoaded = true;
                },
                error => console.log( error )
            );
        }
    },
    created() {
        this.getLocations();
    }
}
</script>

<style lang='less' scoped>
</style>


