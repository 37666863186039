<template>
<div id='paginator'>
    <div class='paginator-link' 
        v-bind:class="{'disabled': currentPage === 1 }"
        v-on:click="selectPage(1)">&lt;&lt;</div>
    <div class='paginator-link'
        v-bind:class="{'disabled': currentPage === 1 }"
        v-on:click="selectPage(currentPage - 1)">&lt;</div>
    <div class='paginator-link' 
        v-for='page in visibleLinks' :key='page'
        v-bind:class="{'selected': page === currentPage}"
        v-on:click="selectPage(page)">
        {{ page }}
    </div>
    <div class='paginator-link'
        v-bind:class="{'disabled': currentPage === total }"
        v-on:click="selectPage(currentPage + 1)">&gt;</div>
    <div class='paginator-link'
        v-bind:class="{'disabled': currentPage === total }"
        v-on:click="selectPage(total)">&gt;&gt;</div>
</div>
</template>

<script>
export default {
    name: "Paginator",
    props: [ 'currentPage', 'total' ],

    data() {
        return {
            visibleLinksCount: 11,
        }
    },
    methods: {
        selectPage( pageNum ) {
            if ( 
                pageNum !== this.currentPage &&
                pageNum >= 1 &&
                pageNum <= this.total  
            ) {
                this.$emit( 'pageSelected', pageNum );
            }
        },
    },
    computed: {
        visibleLinks() {
            let numbers = [];
            let start = this.currentPage - Math.floor( this.visibleLinksCount / 2 );
            if ( start < 1 ) { start = 1 }

            for ( let i = start; i <= this.total && i <= start + this.visibleLinksCount; i++ ) {
                numbers.push( i );
            }
            return numbers;
        }
    }

}
</script>

<style lang="less" scoped>
@import '../../../../styles/variables.less';
#paginator {
    display: flex;
    justify-content: right;
    color: rgb( 80, 80, 80 );
    background-color: white;
    height: 100%;

    .paginator-link {
        width: 35px;
        font-size: 0.95em;
        font-family: 'Quicksand';
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: default;
    }

    .paginator-link:hover:not(.selected):not(.disabled) { 
        background-color: rgb( 220, 230, 230 );
        cursor: pointer;
    }

    .selected {
        background-color: @blue;
        color: white;
    }

    .disabled {
        color: rgb( 180, 180, 180 );
    }
}
</style>

