<template>

<div class='box'>
    <div class="box-header">Add Employee</div>
    <div class="box-content content-padding">
        <EmployeeEditor :user='user'></EmployeeEditor>
    </div>
</div>

</template>

<script>
import EmployeeEditor from '../editor/EmployeeEditor.vue';

export default {
    name: 'AddEmployee',
    components: {
        EmployeeEditor
    },
    data() {
        return {
            user: {}
        }
    }
}

</script>

<style lang="less" scoped>

</style>
