<template>
<div id='modal-container'>
    <transition name='backdrop'>
        <div id='backdrop'
            v-if='visible'
            v-on:click='close'>
        </div>
    </transition>

    <transition name='modal'>
        <div id='modal' v-if='visible'>
            <div id='header'>
                {{ title || "Confirm Action" }}
            </div>
            <div id='content'>
                <div v-if='!isCheckingPassword'>
                    {{ message }}

                    <div id="confirm-form-group" >
                        <label>To continue, please confirm your password:</label>
                        <input class='form-control' type='password' placeholder="Password" v-model='password'>
                    </div>

                    <div v-if='hasError' id='error-message'>
                        Password is incorrect.
                    </div>
                </div>
                <div v-else>
                    <BarLoader></BarLoader>
                </div>
            </div>
            <div id='footer'>
                <button type='button' 
                    class='btn btn-sm btn-danger'
                    v-on:click='confirmPassword()'
                    :disabled='isCheckingPassword'>Continue</button>

                <button type='button' 
                    class='btn btn-sm btn-primary'
                    v-on:click='close()'>Cancel</button>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import Modal from './Modal.vue';
import AuthMixin from '../../../../mixins/AuthMixin';
import AuthService from '../../../auth/auth-service.js';
import BarLoader from '../loaders/bar/BarLoader.vue';

export default {
    extends: Modal,
    name: 'PasswordConfirmModal',
    mixins: [ AuthMixin ],
    props: [ 
        'message',
        'loadingMessage',
        'title' 
    ],
    components: {
        Modal,
        BarLoader
    },  
    data() {
        return {
            isCheckingPassword: false,
            authService: new AuthService(),
            password: '',
            hasError: false,
        }
    },
    methods: {
        close() {
            if ( !this.isLoading ) {
                this.$emit( 'close', false );
            }
        },
        confirmPassword() {
            this.isCheckingPassword = true;
            this.authService.login( {
                name: this.AuthenticatedUser.code,
                password: this.password
            } ).then(
                function() {
                    this.$emit( 'close', true );
                    this.isCheckingPassword = false;
                }.bind( this ),
                function() {
                    this.hasError = true;
                    this.isCheckingPassword = false;
                }.bind( this )
            )
        }
    },
}
</script>

<style lang="less" scoped>
@import './Modal.less';

#content {
    text-align: left;
}

#confirm-form-group {
    text-align: left;
    margin-top: 40px;
    display: block;
    
    label {
        font-size: 0.85em;
        color: rgb( 130, 130, 130 );
    }

    .form-control {
        display: block;
        width: 100%;
        margin-top: 10px;
        border-bottom: 1px solid rgb( 220, 220, 220 );
        padding: 8px 12px;

    }
}

#error-message {
    color: #e84118;
    margin-top: 15px;
    text-align: left;
    font-style: italic;
    font-size: 0.9em;
}
</style>

