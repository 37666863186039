import Vue from 'vue';

export default class {
    
    getSettings() {
        return Vue.http.get( 'settings/get' );
    }

    getSetting( name ) {
        return Vue.http.get( 'settings/get?setting=' + name );
    }

    setSetting( name, value ) {
        return Vue.http.post( 'settings/set', [{
            setting: name,
            value: value
        }] );
    }

    setSettings( settings ) {
        return Vue.http.post( 'settings/set', settings );
    }
}