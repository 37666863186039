import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import AuthState from './states/AuthState.js';

import Auth from './components/auth/Auth.vue'
import Pages from './components/pages/Pages.vue'
import Status from './components/status/Status.vue'

import PagesRoutes from './components/pages/routes.js';
import StatusRoutes from './components/status/StatusRoutes.js';

import ClockinComponent from './components/pages/clockin/ClockinComponent.vue';

Vue.use( VueRouter );
Vue.use( Vuelidate );

const routes = [
    {
        path: '/clockin',
        component: ClockinComponent
    },
    { 
        path: '/login', component: Auth 
    },
    { 
        path: '/status', 
        component: Status,
        children: StatusRoutes
    },
    { 
        path: '/', 
        component: Pages,
        beforeEnter: ( to, from, next ) => {
            if ( !AuthState.isLoggedIn ) {
                next( { path: '/login', query: { redirect: to.path } } );
            }
            else {
                next();
            }
        },
        children: PagesRoutes
    },
    {
        path: '*', redirect: '/status/404'
    }
]

export const router = new VueRouter({
    mode: 'history',
    routes: routes
});