import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource';
import VueTouch from 'vue-touch';
import { router } from './router.js';

Vue.config.productionTip = false

/*
 *  Vue Resource setup
 */
Vue.use( VueResource );

if ( location.host.startsWith( 'clocker' ) ) {
  Vue.http.options.root = 'https://clockerback.us.to/api';
}
else if ( location.host.startsWith( 'localhost' ) ) {
  Vue.http.options.root = 'http://localhost:8000/api';
}
else {
  Vue.http.options.root = 'http://192.168.2.2:8000/api';
}
Vue.http.headers.common['Authorization'] = ''; 

/*
 * Vue Touch and Hammer setup
 */
// Enable user text select 
delete Hammer.defaults.cssProps.userSelect;
Vue.use( VueTouch, {name: 'v-touch'} );

VueTouch.config.swipe = {
  direction: 'horizontal'
};

new Vue({
  router,
  render: (h) => h( App )

}).$mount('#app')
