<template src='./Settings.html'></template>

<script>

import SettingsService from './SettingsService.js';
import StatusModal from '../shared/modal/StatusModal.vue';
import AuthMixin from '../../../mixins/AuthMixin.js';
import AuthService from '../../auth/auth-service.js';
import AuthState from '../../../states/AuthState.js';

export default {
    name: 'Settings',
    components: {
        StatusModal
    },
    mixins: [ AuthMixin ],
    data() {
        return {
            weekStartDay: 0,
            overtime: 0,
            warnIfWorkedOver: 0,
            insuranceMinimum: 0,

            settingsService: new SettingsService(),
            authService: new AuthService(),

            settingsLoading: false,
            settingsMessage: ''
        }
    },
    created() {
        
        this.weekStartDay = +this.ApplicationSettings["WEEK_START_DAY"];
        this.overtime = +this.ApplicationSettings["MAX_WORK_TIME"];
        this.warnIfWorkedOver = +this.ApplicationSettings["WARN_IF_WORKED_OVER"];
        this.insuranceMinimum = +this.ApplicationSettings["INSURANCE_MINIMUM"];
    },
    methods: {
        saveSettings() {
            this.settingsLoading = true;
            this.settingsMessage = "Saving...";
            this.$refs.StatusModal.show();

            this.settingsService.setSettings([
                {
                    setting: 'WEEK_START_DAY',
                    value: this.weekStartDay
                },
                {
                    setting: 'MAX_WORK_TIME',
                    value: this.overtime
                },
                {
                    setting: 'WARN_IF_WORKED_OVER',
                    value: this.warnIfWorkedOver
                },
                {
                    setting: 'INSURANCE_MINIMUM',
                    value: this.insuranceMinimum
                }
            ]).then(
                response => {
                    this.settingsLoading = false;
                    this.settingsMessage = "Settings saved successfully";
                    // Refresh the token to receive the updated settings
                    this.authService.refreshToken().then(
                        response => {
                            let tokenObj = this.authService.parseToken( response.body.token );
                            this.authService.storeCookie( tokenObj );
                            AuthState.token = tokenObj;
                            AuthState.isLoggedIn = true;
                        },
                        error => {
                            AuthState.isLoggedIn = false;
                            this.authService.logout();
                            this.$router.push({ name: 'logout' });
                        }
                    );
                },
                error => {
                    if ( error.status === 403 ) {
                        this.$router.push({ name: '403' });
                    }
                    else if ( error.status === 422 ) {
                        this.settingsLoading = false;
                        this.settingsMessage = "Error saving settings. Make sure all values are correct";
                    }
                }
            );
        }
    }
}
</script>

<style lang="less" scoped>

</style>
