import Vue from 'vue';

export default class {

    list( filters, page ) {
        if ( !Number.isInteger( page ) ) {
            page = 1;
        }
        return Vue.http.post( `user/list?page=${page}`, filters );
    }

    getUser( id ) {
        return Vue.http.get( `user/${id}` );
    }

    getCurrentUser() {
        return Vue.http.get( `user/current` );
    }

    validateLoginName( name, exclude ) {
        return Vue.http.post( 'user/validate-name', { login_name: name, exclude: exclude } );
    }

    getUserTimecards( id, from, to ) {
        return Vue.http.get( `timecard/user/${id}?from=${from.toISODate()}&to=${to.toISODate()}` );
    }

    getCurrentUserTimecards( from, to ) {
        return Vue.http.get( `timecard/user/current?from=${from.toISODate()}&to=${to.toISODate()}` );
    }

    updateUserTimecard( timecard_id, changes ) {
        return Vue.http.post( `timecard/edit/${timecard_id}`, changes );
    }

    createUserTimecard( timecard ) {
        return Vue.http.post( `timecard/create`, timecard );
    }

    setTimecardNote( id, note ) {
        return Vue.http.post( `timecard/set-note/${id}`, {
            note: note
        } );
    }

    deleteUserTimecard( timecard_id ) {
        return Vue.http.delete( `timecard/delete/${timecard_id}` );
    }

    updateUser( id, data ) {
        return Vue.http.patch( `user/update/${id}`, data );
    }

    createUser( data ) {
        return Vue.http.put( `user/register`, data );
    }

    deleteUser( id, reason ) {
        return Vue.http.post( `user/delete/${id}`, {
            reason: reason
        } );
    }

    undeleteUser( id ) {
        return Vue.http.get( `user/undelete/${id}` );
    }

    getWorkStatistics( user_id ) {
        return Vue.http.get( `stats/employee/work/${user_id}` );
    }

    setInsuranceStatus( user_id, insurance_status ) {
        return Vue.http.post( `user/insurance/${user_id}`, {
            insurance_status: insurance_status
        } );
    }


}