import Status404 from './404.vue';
import Status403 from './403.vue';

export default [
    {
        path: '404',
        component: Status404,
        name: '404'
    },
    {
        path: '403',
        component: Status403,
        name: '403'
    }
]