<template>
<form class="filter-form">
    <div class='form-row'>
        <label>Week: </label>
        <select v-model='selectedMonth' class='form-control'>
            <option v-for='( month, index ) in months' v-bind:key='index' :value='index+1'>
                {{ month }}
            </option>
        </select>
        <select v-model='selectedWeek' class='form-control'>
            <!-- <option value='0'>All Month</option> -->
            <option v-for='( week, index ) in weeks' v-bind:key='index' :value='index'>
                Ending {{ months[week.month-1] }} {{ week.day }}
            </option>
        </select>
        <input v-model='selectedYear' class='form-control' type='number' min='1900'>
    </div>
</form>
</template>

<script>
import { DateTime } from 'luxon';
import AuthMixin from '../../../../mixins/AuthMixin';

export default {
    name: 'WeekPicker',
    props: [ 'month', 'year', 'week' ],
    mixins: [ AuthMixin ],
    data() {
        return {
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ],
            weeks: [],
            selectedMonth: this.month || DateTime.local().month,
            selectedYear: this.year || DateTime.local().year,
            selectedWeek: 0,
            endOfWeek: 1
        };
    },
    methods: {
        dateChanged( val, oldVal ) {
            if ( val == oldVal && val != null ) { return }

            let date_from, date_to;
            date_to = DateTime.fromObject({ year: +this.selectedYear, month: +this.selectedMonth, day: 1 });
            // Find the end of the first week of the month
            while( date_to.weekday != this.endOfWeek ) {
                date_to = date_to.plus({ days: 1 });
            }
            date_to = date_to.plus({ weeks: +this.selectedWeek });
            date_from = date_to.minus({ days: 6 });

            this.$emit( 'change', {
                from: date_from,
                to: date_to
            } );
        },
        generateWeeks() {
            this.weeks = []
            let startDate = DateTime.fromObject({
                year: +this.selectedYear,
                month: +this.selectedMonth,
                day: 1
            });
            // Set the start date to the first end of week
            while ( startDate.weekday != this.endOfWeek ) {
                startDate = startDate.plus({ days: 1 });
            }

            while( true ) {
                this.weeks.push( startDate );

                startDate = startDate.plus({ weeks: 1 })
                if ( startDate.month > this.selectedMonth || startDate.year > this.selectedYear ) {
                    break;
                }
            }
        },
        getCurrentWeek() {
            let currentDate = DateTime.local().set({ hour: 0, minute: 0 });
            while( currentDate.weekday != this.endOfWeek ) {
                currentDate = currentDate.plus({ days: 1 });
            }
            this.selectedYear = currentDate.year;
            this.selectedMonth = currentDate.month;
            this.generateWeeks();

            for ( let i = 0; i < this.weeks.length; i++ ) {
                if ( currentDate <= this.weeks[i].set({ hour: 1 }) ) {
                    this.selectedWeek = i;
                    return;
                }
            }
        }
    },
    created() {
        this.endOfWeek = +this.ApplicationSettings["WEEK_START_DAY"] > 1 ? +this.ApplicationSettings["WEEK_START_DAY"] - 1 : 7;
        this.generateWeeks()
        if ( this.week == null ) {
            this.getCurrentWeek();
        }
        this.dateChanged()
    },
    watch: {
        selectedMonth: [ 'dateChanged', 'generateWeeks' ],
        selectedYear: [ 'dateChanged', 'generateWeeks' ],
        selectedWeek: 'dateChanged'
    }
}
</script>

<style lang="less" scoped>
</style>
