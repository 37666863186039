<template>
<div class='loader-container'>
    <div class='message-container' v-if='message'>
        {{ message }}
    </div>
    <div class="loading">
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
    </div>
</div>
</template>

<script>
export default {
    name: "BarLoader",
    props: [ 'message' ]
}
</script>

<style lang='less' scoped>
@color: #636e72;
.loader-container {
    //display: flex;
    flex-direction: column;
    justify-items: center;

    .message-container {
        text-align: center;
        color: @color;
        font-size: 0.9em;
        font-family: 'Raleway', sans-serif;
    }

    .loading {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .loading-bar {
            display: inline-block;
            background-color: @color;
            width: 4px;
            height: 18px;
            border-radius: 4px;
            animation: loading 1s ease-in-out infinite;
            margin-right: 5px;
        }
        .loading-bar:nth-child(1) {
            animation-delay: 0;
        }
        .loading-bar:nth-child(2) {
            animation-delay: 0.09s;
        }
        .loading-bar:nth-child(3) {
            animation-delay: .18s;
        }
        .loading-bar:nth-child(4) {
            animation-delay: .27s;
        }

        @keyframes loading {
            0% {
                transform: scale(1);
            }
            20% {
                transform: scale(1, 2.2);
            }
            40% {
                transform: scale(1);
            }
        }
    }
}


</style>