<template src='./EmployeeEditor.html'></template>

<script>
import EmployeeService from '../EmployeeService.js';
import LocationService from '../../locations/LocationService.js';
import AuthMixin from '../../../../mixins/AuthMixin.js';
import StatusModal from '../../shared/modal/StatusModal.vue';
import Modal from '../../shared/modal/Modal.vue';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import flatpickr from 'flatpickr';
import _ from 'lodash';
import { DateTime } from 'luxon';

export default {
    name: 'EmployeeEditor',
    props: [ 'user' ],
    mixins: [ AuthMixin ],
    components: {
        StatusModal,
        Modal
    },
    data() {
        return {
            locationService: new LocationService(),
            employeeService: new EmployeeService(),
            locations: [],
            saveStatus: '',
            savingEmployee: false,
            
            newUser: {
                id: this.user.id || null,
                code: this.user.code || null,
                name: this.user.name || '',
                login_name: this.user.login_name || '',
                newPassword: '',
                location_id: this.user.location != null ? this.user.location.id : 0,
                type: this.user.type || 'EMPLOYEE',
                created_at: DateTime.fromISO( this.user.created_at ),
            },

            deleteReason: '',

            loginNameValid: true,
            loginNameErrorMessage: '',
            loginNameLoading: false,

            datePicker: null,
        }
    },
    validations: {
        newUser: {
            name: {
                required,
                minLength: minLength( 4 ),
            },
            login_name: { 
                required: function( val ) {
                    if ( this.newUser.type == 'EMPLOYEE' ) return true;
                    
                    return val.length > 0;
                }, 
                noSpaces: function( val ) { 
                    return !/\s/.test( val.trim() ) },
                valid: function() { 
                    if ( this.newUser.type == 'EMPLOYEE' ) return true;

                    return this.loginNameValid 
                }
            },
            location_id: {
                location_correct: function( value ) {
                    if ( this.newUser.type === 'LOCATION_ADMIN' ) {
                        return value > 0;
                    }
                    return true;
                }
            },
            newPassword: {
                required: function( value ) {
                    if ( this.newUser.type == 'EMPLOYEE' ) return true;
                    if ( !this.newUser.code && value.length < 4 ) {
                        return false;
                    }
                    return true;
                }
            }
        },

        deleteReason: {
            required,
            minLength: minLength( 4 ),
        }
    },
    methods: {
        getLocations() {
            this.locationService.list().then(
                request => this.locations = request.body.data,
                error => console.log( error )
            );
        },
        loginNameChanged: _.debounce( function() {
            if ( this.$v.newUser.login_name.$invalid ) {
                // Set name to valid so the login error message from server 
                // does not show up with other errors from vuelidator
                this.loginNameValid = true;
                return;
            }
            this.loginNameLoading = true;
            this.employeeService.validateLoginName( this.newUser.login_name, this.newUser.id ).then(
                response => {
                    this.loginNameValid = response.body.valid;
                    this.loginNameErrorMessage = response.body.message;
                },
                error => { this.loginNameValid = true },
            )
            .finally( () => this.loginNameLoading = false );
        }, 500 ),
        saveUser() {
            this.$v.$touch();
            if ( this.$v.newUser.$anyError ) {
                this.saveStatus = "There are errors in your form. Please fill out all the fields correctly";
                this.$refs.StatusModal.show();
                return;
            }

            this.saveStatus = "Saving...";
            this.savingEmployee = true;
            this.$refs.StatusModal.show();

            let data = {
                name: this.newUser.name,
                login_name: this.newUser.login_name,
            };
            if ( this.location_id !== 0 ) {
                data.location_id = this.newUser.location_id;
            }
            // Set type if current authenticated user has that permission
            if ( this.IsAdmin() ) {
                data.type = this.newUser.type;
                data.created_at = this.newUser.created_at.toISODate();
            }
            // Check if a new password is provided
            if ( this.newUser.newPassword.length > 0 ) {
                data.password = this.newUser.newPassword;
            }

            // Create or update the user
            if ( this.newUser.code !== null ) {
                this.employeeService.updateUser( this.newUser.id, data ).then(
                    response => {
                        this.saveStatus = "Changes Saved"
                        this.savingEmployee = false;
                    },
                    error => {
                        this.handleSaveError( error );
                        this.savingEmployee = false;
                    }
                );
            }
            else {
                this.employeeService.createUser( data ).then(
                    response => {
                        this.saveStatus = "Changes Saved"
                        this.savingEmployee = false;
                        this.newUser.name = '';
                        this.newUser.login_name = '';
                    },
                    error => {
                        this.handleSaveError( error );
                        this.savingEmployee = false;
                    }
                );
            }
        },
        handleSaveError( response ) {
            let errorMessage = "Server error. Please make sure all the fields are correct or try again.";
            if ( response.status === 400 ) {
                errorMessage = response.body.message || "Error. Please make sure all fields are correct";
            }
            this.saveStatus = errorMessage;
        },
        suggestLoginName() {
            let name = this.newUser.name;
            this.newUser.login_name = name.replace( /\s/gi, '' ).toLowerCase();
        },
        deleteUser() {
            this.$refs.DeleteUserModal.close();
            this.saveStatus = "Deleting employee...";
            this.savingEmployee = true;
            this.$refs.StatusModal.show();

            this.employeeService.deleteUser( this.newUser.id, this.deleteReason ).then(
                function( success ) {
                    this.saveStatus = "Employee deleted";
                    this.$router.push( '/employee/list' );
                }.bind( this ),
                error => this.saveStatus = "Error deleting employee"
            )
            .finally( () => this.savingEmployee = false );
        },

        // Revalidate the login name
        userTypeChanged() {
            if ( this.newUser.type == 'EMPLOYEE' ) return;

            this.loginNameChanged();
            this.createDatePicker();
            this.$v.newUser.login_name.$touch();
        },

        createDatePicker() {
            if ( this.newUser.id == null ) return;
            
            if ( this.datePicker != null ) this.datePicker.destroy();

            setTimeout( () => {
                this.datePicker = flatpickr( this.$refs.hire_date, {
                    dateFormat: 'm/d/Y',
                    onChange: ( dates ) => {
                        this.newUser.created_at = DateTime.fromJSDate( dates[0] );
                    },
                    defaultDate: DateTime.fromISO( this.newUser.created_at ).toJSDate()
                } );
            } )
            
        }
    },
    created() {
        if ( this.IsAdmin() ) {
            this.getLocations();
        }
        else {
            this.locations = [ this.AuthenticatedUser.location ];
        }
    },
    mounted() {
        if ( !this.IsAdmin() || this.newUser.code == null || this.user.deleted_at != null ) return;

        this.createDatePicker();
        
    }
}
</script>

<style lang="less" scoped>

</style>

