import Vue from 'vue';
import { DateTime } from 'luxon';

export default class {
    constructor() {}

    login( data ) {
        return Vue.http.post( 'login', data );
    }

    loginFromCookie() {
        let token = this.retrieveJWTFromCookie();
        
        if ( token === null ) {
            return null;
        }

        let tokenObj = this.parseToken( token );
        if ( tokenObj === null ) { return null }
        
        // Check if token has expired
        if ( tokenObj.header.exp < DateTime.local() ) {
            return null;
        }
        
        return tokenObj;
    }

    logout() {
        document.cookie = "jwt_token=0; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    refreshToken() {
        let token = this.retrieveJWTFromCookie();
        return Vue.http.get( 'refresh', {
            headers: {
                Authorization: token
            }
        } );
    }

    storeCookie( token ) {
        if ( token === null ) { return }
        document.cookie = "jwt_token=" + token.tokenString;
    }

    retrieveJWTFromCookie() {
        // Retrieve jwt token from cookie string
        let result = /jwt_token=([^;]+);?/.exec( document.cookie );
        if ( result === null ) {
            return null;
        }

        return result[1];
    }

    parseToken( token ) {
        let data = /([\w=]+)\.([\w=]+)\.[\w=]+/.exec( token );
        if ( data === null ) { return null }
        let header = JSON.parse( atob( data[1] ) );
        let payload = JSON.parse( atob( data[2] ) );

        return {
            tokenString: token,
            header: {
                iat: DateTime.fromISO( header.iat ),
                exp: DateTime.fromISO( header.exp )
            },
            payload: payload
        }
    }
}