import AuthState from '../states/AuthState.js';

export default {
    data() {
        return {
            AuthenticatedUser: AuthState.isLoggedIn ? AuthState.token.payload.user : null,
            ApplicationSettings: AuthState.isLoggedIn ? AuthState.token.payload.settings : null,
            IsLoggedIn: AuthState.isLoggedIn
        }
    },
    methods: {
        IsAdmin() {
            return this.AuthenticatedUser.type === 'ADMIN';
        },
        IsLocationAdmin() {
            return this.AuthenticatedUser.type === 'LOCATION_ADMIN';
        },
        IsEmployee() {
            return this.AuthenticatedUser.type === 'EMPLOYEE';
        }
    }
}